




import { Component, Vue } from 'vue-property-decorator'
import LayoutDefault from '@/layouts/LayoutDefault.vue'

@Component({
  components: {
    LayoutDefault
  }
})
export default class Todo extends Vue {}
